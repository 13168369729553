import { SET_USER, REMOVE_USER, RESET_USER } from "../actions";

const initialState = {
  token: localStorage.getItem("token") || "",
  profile: JSON.parse(localStorage.getItem("profile")) || {},
};

const createSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return {
        ...state,
        ...payload,
      };
    case RESET_USER:
      return initialState;
    default:
      return state;
  }
};

export default createSoalReducer;
