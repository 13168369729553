import { ID_SOAL_AKM } from "src/data/config";
import {
  SET_LAYOUT_SOAL,
  SET_JUMLAH_ARTIKEL,
  TOGGLE_IS_GROUP_SOAL,
  SET_JUMLAH_JAWABAN,
  SET_JUDUL_ARTIKEL,
  SET_ISI_ARTIKEL,
  SET_TIPE_JAWABAN,
  SET_TOTAL_OPSI_PG,
  SET_PERTANYAAN_JAWABAN,
  SET_JAWABAN_BENAR_PG,
  SET_TEXT_JAWABAN,
  SET_POIN_SOAL,
  SET_LEVEL_SOAL,
  SET_KATEGORI_SOAL,
  SET_TIPE_MATERI,
  RESET_CREATE_SOAL,
  SET_JAWABAN_BENAR_PGKOMPLEX,
  TOGGLE_GUNAKAN_EDITOR_PERTANYAAN,
  SET_INFORMASI_SOAL,
  SET_PEMBAHASAN_SOAL,
  SET_DATA_SOAL,
  SET_JAWABAN_BENAR_SALAH,
  SET_TEXT_JAWABAN_ISIAN_SINGKAT,
  SET_CURRENT_ARTICLE,
  SET_LABEL_BENAR_SALAH,
  SET_EXAM_ID,
  SET_POLA_MENJODOHKAN,
  SET_STRUKTUR_JAWABAN_MENJODOHKAN,
  SET_JAWABAN_MENJODOHKAN,
  SET_STATUS_POLA_MENJODOHKAN,
  SET_TOTAL_OPSI_LINGKUNGAN,
  SET_TOTAL_PERTANYAAN_LINGKUNGAN,
  SET_TEXT_OPSI_LINGKUNGAN,
  SET_TEXT_PERTANYAAN_LINGKUNGAN,
  SET_JAWABAN_SURVEY_LINGKUNGAN,
} from "../actions";

const dataArtikelInitial = [
  {
    judul: "Artikel 1",
    artikel: "",
  },
];
const dataJawabanIsianSingkat = [
  {
    text_jawaban: "",
    status_jawaban: "1",
  },
];
//  "jawaban": [
//             {
//                 "text_jawaban": "Saya bisa cepat beradaptasi dengan teman - teman",
//                 "status_jawaban": "3"
//             },
//             {
//                 "text_jawaban": "Saya susah mendapat teman",
//                 "status_jawaban": "1"
//             },
//             {
//                 "text_jawaban": "Saya merasa asing",
//                 "status_jawaban": "0"
//             }
//         ],
//         "pola_jawaban": [
//             {
//                 "label": "Sangat Tidak Setuju",
//                 "status_jawaban": "0"
//             },
//             {
//                 "label": "Tidak Setuju",
//                 "status_jawaban": "1"
//             },
//             {
//                 "label": "Setuju",
//                 "status_jawaban": "2"
//             },
//             {
//                 "label": "Sangat Setuju",
//                 "status_jawaban": "3"
//             }
//         ]

const polaJawabanSurveyLingkungan = [
  {
    label: "",
    status_jawaban: "0",
  },
  {
    label: "",
    status_jawaban: "1",
  },
  {
    label: "",
    status_jawaban: "2",
  },
  {
    label: "",
    status_jawaban: "3",
  },
];
const dataJawabanSurveyLingkungan = [
  {
    text_jawaban: "",
    status_jawaban: "0",
  },
  {
    text_jawaban: "",
    status_jawaban: "0",
  },
  {
    text_jawaban: "",
    status_jawaban: "0",
  },
];
// const dataJawabanMenjodohkan = [
//   {
//     text_jawaban: "Aku",
//     label: "Kiri",
//     status_jawaban: "1",
//   },
//   {
//     text_jawaban: "Kakak",
//     label: "Kiri",
//     status_jawaban: "2",
//   },
//   {
//     text_jawaban: "Ibu",
//     label: "Kiri",
//     status_jawaban: "3",
//   },
//   {
//     text_jawaban: "Ayah",
//     label: "Kiri",
//     status_jawaban: "4",
//   },
//   {
//     text_jawaban: "Eyang",
//     label: "Kiri",
//     status_jawaban: "5",
//   },
//   {
//     text_jawaban: "Periang",
//     label: "Kanan",
//     status_jawaban: "1",
//   },
//   {
//     text_jawaban: "Menghargai orang lain",
//     label: "Kanan",
//     status_jawaban: "2",
//   },
//   {
//     text_jawaban: "Pekerja Keras",
//     label: "Kanan",
//     status_jawaban: "3",
//   },
//   {
//     text_jawaban: "Selalu ingin tahu",
//     label: "Kanan",
//     status_jawaban: "4",
//   },
//   {
//     text_jawaban: "Penurut",
//     label: "Kanan",
//     status_jawaban: "5",
//   },
//   {
//     text_jawaban: "Penyayang",
//     label: "Kanan",
//     status_jawaban: "6",
//   },
// ];
const dataJawabanMenjodohkan = [
  {
    text_jawaban: "",
    label: "Kiri",
    status_jawaban: "1",
  },
  {
    text_jawaban: "",
    label: "Kiri",
    status_jawaban: "2",
  },
  {
    text_jawaban: "",
    label: "Kiri",
    status_jawaban: "3",
  },
  {
    text_jawaban: "",
    label: "Kiri",
    status_jawaban: "4",
  },
  {
    text_jawaban: "",
    label: "Kiri",
    status_jawaban: "5",
  },
  {
    text_jawaban: "",
    label: "Kanan",
    status_jawaban: "1",
  },
  {
    text_jawaban: "",
    label: "Kanan",
    status_jawaban: "2",
  },
  {
    text_jawaban: "",
    label: "Kanan",
    status_jawaban: "3",
  },
  {
    text_jawaban: "",
    label: "Kanan",
    status_jawaban: "4",
  },
  {
    text_jawaban: "",
    label: "Kanan",
    status_jawaban: "5",
  },
];

const polaJawabanMenjodohkan = [
  {
    label: "Kiri",
  },
  {
    label: "Kanan",
  },
  {
    status_jawaban: [],
  },
];

const dataJawabanInitial = {
  tipe_jawaban: "pilihan_ganda",
  pertanyaan: "",
  jawaban: [
    {
      text_jawaban: "",
      status_jawaban: "1",
    },
    {
      text_jawaban: "",
      status_jawaban: "0",
    },
    {
      text_jawaban: "",
      status_jawaban: "0",
    },
    {
      text_jawaban: "",
      status_jawaban: "0",
    },
    {
      text_jawaban: "",
      status_jawaban: "0",
    },
  ],
};

const initialState = {
  id: null,
  exam_id: ID_SOAL_AKM,
  pertanyaan_layout: "vertikal",
  kategori_soal: "literasi",
  tipe_materi: "teks_informasi",
  poin_soal: 1,
  level_soal: 4,
  jumlah_jawaban: 1,
  jumlah_artikel: 1,
  jumlah_opsi: 5,
  is_group_soal: false,
  pembahasan_soal: "Pembahasan Soal",
  informasi_soal: "Informasi Soal",
  data_artikel: dataArtikelInitial,
  data_jawaban: dataJawabanInitial,
  pola_jawaban: undefined,
};

const polaJawaban = [
  {
    label: "Benar",
    status_jawaban: "1",
  },
  {
    label: "Salah",
    status_jawaban: "0",
  },
];

const createSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LAYOUT_SOAL:
      if (payload === "vertikal") {
        const artikels1 = [...state.data_artikel];
        artikels1.length = 1;
        return {
          ...state,
          data_artikel: artikels1,
          jumlah_artikel: 1,
          pertanyaan_layout: payload,
        };
      }
      return {
        ...state,
        pertanyaan_layout: payload,
      };
    case SET_JUMLAH_ARTIKEL:
      const artikels = [...state.data_artikel];
      if (artikels.length > payload) {
        artikels.length = payload;
      } else {
        for (let i = artikels.length; i < payload; i++) {
          artikels.push({ judul: "", artikel: "" });
        }
      }
      return {
        ...state,
        jumlah_artikel: payload,
        data_artikel: artikels,
      };
    case TOGGLE_IS_GROUP_SOAL:
      return {
        ...state,
        is_group_soal: payload,
      };
    case SET_JUMLAH_JAWABAN:
      return {
        ...state,
        jumlah_jawaban: payload,
      };
    case SET_JUDUL_ARTIKEL:
      const artikelJuduls = [...state.data_artikel];
      artikelJuduls[payload.index].judul = payload.judul;
      return {
        ...state,
        data_artikel: artikelJuduls,
      };
    case SET_ISI_ARTIKEL:
      const artikelContents = [...state.data_artikel];
      artikelContents[payload.index].artikel = payload.artikel;
      return {
        ...state,
        data_artikel: artikelContents,
      };
    case SET_CURRENT_ARTICLE:
      const currentArtikel = [...state.data_artikel];
      currentArtikel[payload.index].artikel = payload.artikel;
      currentArtikel[payload.index].judul = payload.judul;
      return {
        ...state,
        data_artikel: currentArtikel,
      };

    case SET_TIPE_JAWABAN:
      if (payload === "isian_singkat") {
        return {
          ...state,
          data_jawaban: {
            ...state.data_jawaban,
            tipe_jawaban: payload,
            jawaban: dataJawabanIsianSingkat,
          },
        };
      }
      if (payload === "benar_salah") {
        return {
          ...state,
          data_jawaban: {
            ...dataJawabanInitial,
            tipe_jawaban: payload,
            pola_jawaban: polaJawaban,
          },
        };
      }
      if (payload === "menjodohkan") {
        return {
          ...state,
          data_jawaban: {
            ...dataJawabanInitial,
            tipe_jawaban: payload,
            pola_jawaban: polaJawabanMenjodohkan,
            jawaban: dataJawabanMenjodohkan,
          },
        };
      }
      if (payload === "survey_lingkungan") {
        return {
          ...state,
          data_jawaban: {
            ...state.data_jawaban,
            tipe_jawaban: payload,
            pola_jawaban: polaJawabanSurveyLingkungan,
            jawaban: dataJawabanSurveyLingkungan,
          },
        };
      }
      return {
        ...state,
        data_jawaban: {
          ...dataJawabanInitial,
          tipe_jawaban: payload,
        },
      };
    case SET_PERTANYAAN_JAWABAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pertanyaan: payload,
        },
      };

    case SET_TOTAL_OPSI_PG:
      if (state.data_jawaban.jawaban.length > payload) {
        state.data_jawaban.jawaban.length = payload;
        return {
          ...state,
          data_jawaban: {
            ...state.data_jawaban,
            jawaban: state.data_jawaban.jawaban,
          },
        };
      } else {
        for (let i = state.data_jawaban.jawaban.length; i < payload; i++) {
          state.data_jawaban.jawaban.push({
            text_jawaban: "",
            status_jawaban: "0",
          });
        }

        return {
          ...state,
          data_jawaban: {
            ...state.data_jawaban,
            jawaban: state.data_jawaban.jawaban,
          },
        };
      }
    case SET_JAWABAN_BENAR_PG:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload) {
              return {
                ...item,
                status_jawaban: "1",
              };
            } else {
              return {
                ...item,
                status_jawaban: "0",
              };
            }
          }),
        },
      };
    case SET_JAWABAN_BENAR_PGKOMPLEX:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                status_jawaban: payload.status_jawaban ? "1" : "0",
              };
            }
            return item;
          }),
        },
      };
    case SET_TOTAL_OPSI_LINGKUNGAN:
      let opsiLingkungan = [...state.data_jawaban.pola_jawaban];
      if (parseInt(payload, 10) < opsiLingkungan.length) {
        opsiLingkungan.length = parseInt(payload, 10);
      } else {
        for (let i = opsiLingkungan.length; i < parseInt(payload, 10); i++) {
          opsiLingkungan.push({
            label: "",
            status_jawaban: String(i),
          });
        }
      }
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: opsiLingkungan,
        },
      };

    case SET_TOTAL_PERTANYAAN_LINGKUNGAN:
      let pertanyaanLingkungan = [...state.data_jawaban.jawaban];
      if (parseInt(payload, 10) < pertanyaanLingkungan.length) {
        pertanyaanLingkungan.length = parseInt(payload, 10);
      } else {
        for (
          let i = pertanyaanLingkungan.length;
          i < parseInt(payload, 10);
          i++
        ) {
          pertanyaanLingkungan.push({
            text_jawaban: "",
            status_jawaban: "0",
          });
        }
      }
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: pertanyaanLingkungan,
        },
      };

    case SET_TEXT_OPSI_LINGKUNGAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: state.data_jawaban.pola_jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                label: payload.label,
              };
            }
            return item;
          }),
        },
      };
    case SET_TEXT_PERTANYAAN_LINGKUNGAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                text_jawaban: payload.text_jawaban,
              };
            }
            return item;
          }),
        },
      };
    case SET_JAWABAN_SURVEY_LINGKUNGAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                status_jawaban: String(payload.status_jawaban),
              };
            }
            return item;
          }),
        },
      };

    case SET_LABEL_BENAR_SALAH:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: state.data_jawaban.pola_jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                label: payload.label,
              };
            }
            return item;
          }),
        },
      };
    case SET_JAWABAN_BENAR_SALAH:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                status_jawaban: payload.status_jawaban,
              };
            }
            return item;
          }),
        },
      };
    case SET_TEXT_JAWABAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: state.data_jawaban.jawaban.map((item, index) => {
            if (index === payload.index) {
              return {
                ...item,
                text_jawaban: payload.text_jawaban,
              };
            }
            return item;
          }),
        },
      };
    case SET_TEXT_JAWABAN_ISIAN_SINGKAT:
      const data_jawaban5 = [...state.data_jawaban];
      data_jawaban5[payload.indexJawaban].jawaban[payload.index].text_jawaban =
        payload.text_jawaban;
      return {
        ...state,
        data_jawaban: data_jawaban5,
      };
    case SET_POIN_SOAL:
      return {
        ...state,
        poin_soal: payload,
      };
    case SET_LEVEL_SOAL:
      return {
        ...state,
        level_soal: payload,
      };
    case SET_KATEGORI_SOAL:
      return {
        ...state,
        kategori_soal: payload.kategori_soal,
        exam_id: payload.exam_id,
        level_soal : payload.level_soal,
      };
    case SET_TIPE_MATERI:
      return {
        ...state,
        tipe_materi: payload.tipe_materi
      };
    case SET_PEMBAHASAN_SOAL:
      return {
        ...state,
        pembahasan_soal: payload,
      };
    case SET_EXAM_ID:
      return {
        ...state,
        exam_id: payload,
      };
    case SET_INFORMASI_SOAL:
      return {
        ...state,
        informasi_soal: payload,
      };

    case SET_JAWABAN_MENJODOHKAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          jawaban: payload,
        },
      };
    case SET_STRUKTUR_JAWABAN_MENJODOHKAN:
      const leftItems = [];
      const rightItems = [];
      for (let i = 0; i < parseInt(payload.left.total, 10); i++) {
        leftItems.push({
          text_jawaban: "",
          label: payload.left.label,
          status_jawaban: String(i + 1),
        });
      }
      for (let j = 0; j < parseInt(payload.right.total, 10); j++) {
        rightItems.push({
          text_jawaban: "",
          label: payload.right.label,
          status_jawaban: String(j + 1),
        });
      }
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: [
            {
              label: payload.left.label,
            },
            {
              label: payload.right.label,
            },
            {
              status_jawaban: [],
            },
          ],
          jawaban: [...leftItems, ...rightItems],
        },
      };
    case SET_POLA_MENJODOHKAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: state.data_jawaban.pola_jawaban.map((item, index) => {
            if (payload.index === index) {
              return {
                ...item,
                label: payload.label,
              };
            }
            return item;
          }),
        },
      };
    case SET_STATUS_POLA_MENJODOHKAN:
      return {
        ...state,
        data_jawaban: {
          ...state.data_jawaban,
          pola_jawaban: state.data_jawaban.pola_jawaban.map((item, index) => {
            if (index === 2) {
              return { status_jawaban: payload };
            }
            return item;
          }),
        },
      };
    case TOGGLE_GUNAKAN_EDITOR_PERTANYAAN:
      return {
        ...state,
        gunakan_editor_pertanyaan: payload,
      };
    case SET_DATA_SOAL:
      return payload;
    case RESET_CREATE_SOAL:
      return {
        ...initialState,
        data_artikel: dataArtikelInitial,
        data_jawaban: dataJawabanInitial,
      };
    default:
      return state;
  }
};

export default createSoalReducer;
