export const SET_LAYOUT_SOAL = "SET_LAYOUT_SOAL";
export const SET_EXAM_ID = "SET_EXAM_ID";
export const SET_JUMLAH_ARTIKEL = "SET_JUMLAH_ARTIKEL";
export const TOGGLE_IS_GROUP_SOAL = "TOGGLE_IS_GROUP_SOAL";
export const SET_JUMLAH_JAWABAN = "SET_JUMLAH_JAWABAN";
export const SET_JUDUL_ARTIKEL = "SET_JUDUL_ARTIKEL";
export const SET_ISI_ARTIKEL = "SET_ISI_ARTIKEL";
export const SET_TIPE_JAWABAN = "SET_TIPE_JAWABAN";
export const SET_TOTAL_OPSI_PG = "SET_TOTAL_OPSI_PG";
export const SET_PERTANYAAN_JAWABAN = "SET_PERTANYAAN_JAWABAN";
export const SET_JAWABAN_BENAR_PG = "SET_JAWABAN_BENAR_PG";
export const SET_JAWABAN_BENAR_PGKOMPLEX = "SET_JAWABAN_BENAR_PGKOMPLEX";
export const SET_TEXT_JAWABAN = "SET_TEXT_JAWABAN";
export const SET_KATEGORI_SOAL = "SET_KATEGORI_SOAL";
export const SET_TIPE_MATERI = "SET_TIPE_MATERI";
export const SET_POIN_SOAL = "SET_POIN_SOAL";
export const SET_LEVEL_SOAL = "SET_LEVEL_SOAL";
export const RESET_CREATE_SOAL = "RESET_CREATE_SOAL";
export const SET_INFORMASI_SOAL = "SET_INFORMASI_SOAL";
export const SET_PEMBAHASAN_SOAL = "SET_PEMBAHASAN_SOAL";
export const SET_DATA_SOAL = "SET_DATA_SOAL";
export const SET_JAWABAN_BENAR_SALAH = "SET_JAWABAN_BENAR_SALAH";
export const SET_JAWABAN_ISIAN_SINGKAT = "SET_JAWABAN_ISIAN_SINGKAT";
export const SET_JAWABAN_MENJODOHKAN = "SET_JAWABAN_MENJODOHKAN";
export const SET_JAWABAN_DRAG_DROP = "SET_JAWABAN_DRAG_DROP";
export const SET_CURRENT_ARTICLE = "SET_CURRENT_ARTICLE";
export const SET_TEXT_JAWABAN_ISIAN_SINGKAT = "SET_TEXT_JAWABAN_ISIAN_SINGKAT";
export const SET_LABEL_BENAR_SALAH = "SET_LABEL_BENAR_SALAH";
export const SET_POLA_MENJODOHKAN = "SET_POLA_MENJODOHKAN";
export const SET_STATUS_POLA_MENJODOHKAN = "SET_STATUS_POLA_MENJODOHKAN";
export const SET_TEXT_OPSI_LINGKUNGAN = "SET_TEXT_OPSI_LINGKUNGAN";
export const SET_TEXT_PERTANYAAN_LINGKUNGAN = "SET_TEXT_PERTANYAAN_LINGKUNGAN";
export const SET_TOTAL_OPSI_LINGKUNGAN = "SET_TOTAL_OPSI_LINGKUNGAN";
export const SET_JAWABAN_SURVEY_LINGKUNGAN = "SET_JAWABAN_SURVEY_LINGKUNGAN";

export const SET_TOTAL_PERTANYAAN_LINGKUNGAN =
  "SET_TOTAL_PERTANYAAN_LINGKUNGAN";

export const SET_STRUKTUR_JAWABAN_MENJODOHKAN =
  "SET_STRUKTUR_JAWABAN_MENJODOHKAN";
export const SET_TEXT_JAWABAN_MENJODOHKAN = "SET_TEXT_JAWABAN_MENJODOHKAN";

export const TOGGLE_GUNAKAN_EDITOR_PERTANYAAN =
  "TOGGLE_GUNAKAN_EDITOR_PERTANYAAN";
