export const ID_SOAL_AKM = 1;
export const ID_SOAL_SURVEY_KARAKTER = 2;
export const EDITOR_CONFIG = {
  extraPlugins: "easyimage,tableresize,editorplaceholder",
  removePlugins: "image",
  editorplaceholder: "Ketik untuk membuat artikel",
  removeDialogTabs: "link:advanced",
  height: 400,
  // filebrowserUploadUrl: "base64",
  toolbarGroups: [
    {
      name: "basicstyles",
      groups: ["basicstyles"],
    },
    {
      name: "links",
      groups: ["links"],
    },
    {
      name: "paragraph",
      groups: ["list", "blocks"],
    },
    {
      name: "document",
      groups: ["mode"],
    },
    {
      name: "insert",
      groups: ["insert"],
    },
    {
      name: "styles",
      groups: ["styles"],
    },
  ],
  // language: "id",
  // uiColor: "#AADC6E",
  removeButtons:
    "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,Source",
};

export const ApiConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
  timeout: 120,
};

export const SYNC_URL =
  process.env.REACT_APP_SYNC_URL || "https://akm.rasyiidujs3.com/semi-online";

export const FIRST_LOGIN_URL =
  process.env.REACT_APP_SYNC_URL || "https://akm.rasyiidujs3.com";

export const shouldLogApi = process.env.REACT_APP_ENVIRONTMENT !== "production";

export const access = {
  0: "Admin Utama",
  1: "Admin MKKS",
  2: "Proktor",
  3: "Sub Proktor",
  5: "Kepala Sekolah",
};

export const UJIAN = {
  1: "AKM Literasi",
  2: "AKM Numerasi",
  3: "AKM Literasi",
  4: "AKM Numerasi",
  5: "Survey Karakter",
  6: "Survey Karakter",
  7: "Survey Lingkungan Belajar",
  8: "Survey Lingkungan",
  9: "Simulasi Literasi",
  // 10: "Simulasi Numerasi",
  11: "Simulasi Survey_karakter",
  // 12: "Simulasi Survey Lingkungan",
  13: "AKM Literasi",
  14: "AKM Numerasi",
  15: "Survey Karakter",
  16: "Survey Lingkungan",
  17: "Simulasi Literasi", //SMA
  18: "Simulasi Numerasi", //SMA
  19: "Simulasi Literasi", //SMP
  20: "Simulasi Numerasi", //SMP
  21: "Simulasi Literasi", //SD
  22: "Simulasi Numerasi", //SD
};

export const statuses = {
  0: "Tidak Aktif",
  1: "Aktif",
};

export const textIndikatorLiterasi = [
  [""],
  [""],
  ["Tingkat Kompetensi Literasi Membaca"],
  [
    "Perlu Intervensi Khusus: Murid belum mampu menemukan dan mengambil informasi eksplisit yang ada dalam teks ataupun membuat interpretasi sederhana.",
  ],
  [
    "Dasar: Murid mampu menemukan dan mengambil informasi eksplisit yang ada dalam teks serta membuat interpretasi sederhana.",
  ],
  [
    "Cakap: Murid mampu membuat interpretasi dan informasi implisit yang ada dalam teks. Mampu membuat simpulan dari hasil integrasi beberapa informasi dalam suatu teks.",
  ],
  [
    "Mahir: Murid mampu mengintegrasikan beberapa informasi lintas teks. Mengevaluasi isi, kualitas, cara penulisan suatu teks, dan bersikap reflektif terhadap isi teks.",
  ],
];
export const textIndikatorNumerasi = [
  [""],
  [""],
  ["Tingkat Kompetensi Numerasi"],
  [
    "Perlu Intervensi Khusus: Murid hanya memiliki pengetahuan matematika yang terbatas.Murid menunjukan penguasaan konsep yang parsial dan keterampilan komputasi yang terbatas.",
  ],
  [
    "Dasar: Murid memiliki keterampilan dasar matematika. Komputasi dasar dalam bentuk persamaan langsung, konsep dasar terkait geometri dan statistika, serta menyelesaikan masalah matematika sederhana yang rutin.",
  ],
  [
    "Cakap: Murid mampu mengaplikasikan pengetahuan matematika yang dimiliki dalam konteks yang lebih beragam.",
  ],
  [
    "Mahir: Murid mampu bernalar untuk menyelasikan masalah kompleks serta non-rutin berdasarkan konsep matematika yang dimilikinya.",
  ],
];

export const getHasilAKMLiterasi = (nilai) => {
  const n = parseFloat(nilai);
  if (n >= 30 && n < 70) {
    return "Dasar";
  } else if (n >= 70 && n < 90) {
    return "Cakap";
  } else if (n >= 90) {
    return "Mahir";
  } else {
    return "Perlu Intervensi Khusus";
  }
};

export const getHasilAKMNumerasi = (nilai) => {
  const n = parseFloat(nilai);
  if (n >= 55 && n < 80) {
    return "Dasar";
  } else if (n >= 80 && n < 95) {
    return "Cakap";
  } else if (n >= 95) {
    return "Mahir";
  } else {
    return "Perlu Intervensi Khusus";
  }
};

export const getHasilSurveyKarakter = (nilai) => {
  // If skor <=25 (Memerlukan Bimbingan) dan tampilkan Indikatornya
  // If skor >25 and skor <=50 (Mulai Berkembang) dan tampilkan Indikatornya
  // If skor >50 and skor <=75 (Berkembang) dan tampilkan Indikatornya
  // If skor >75 and skor <=100 (Membudaya) dan tampilkan Indikatornya

  const n = parseFloat(nilai);
  if (n > 25 && n <= 50) {
    return "Mulai Berkembang";
  } else if (n > 50 && n <= 75) {
    return "Berkembang";
  } else if (n > 75) {
    return "Membudaya";
  } else {
    return "Memerlukan Bimbingan";
  }
};

export const examLiterasi = [1, 3, 13];
export const examNumerasi = [2, 4, 14];
export const idExamAkm = [1, 2, 3, 4, 13, 14];
export const examSurveyKarakter = [5, 6, 15];
export const examSurveyLingkungan = [7, 8, 16];
export const examSimulasi = [17, 18, 19, 20, 21, 22];
export const nilaiAKM = {
  "Perlu Intervensi Khusus": 0,
  Dasar: 0,
  Cakap: 0,
  Mahir: 0,
};
export const nilaiSurveyKarakter = {
  "Memerlukan Bimbingan": 0,
  "Mulai Berkembang": 0,
  Berkembang: 0,
  Membudaya: 0,
};

export const EXAM_ID_NUMERASI = {
  SMP: 4,
  SD: 14,
  SMA: 2,
};

export const EXAM_ID_LITERASI = {
  SMP: 3,
  SD: 13,
  SMA: 1,
};

export const TOTAL_SOAL_AKM = { sma: 36, smp: 36, sd: 30 };

export const TOTAL_SOAL_SIMULASI = { sma: 12, smp: 12, sd: 9 };
