import {
  SET_LIST_PROKTOR,
  RESET_LIST_USER,
  SET_LIST_PESERTA,
} from "../actions";

const initialState = {
  default: [],
  proktor: [],
  peserta: [],
};

const createSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_PESERTA:
      return {
        ...state,
        peserta: payload,
      };
    case SET_LIST_PROKTOR:
      return {
        ...state,
        proktor: payload,
      };
    case RESET_LIST_USER:
      return initialState.default;
    default:
      return state;
  }
};

export default createSoalReducer;
