import { ID_SOAL_AKM } from "src/data/config";
import { SET_LIST_SOAL_AKM, RESET_LIST_SOAL_AKM } from "../actions";
const initialState = [];

const createSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_SOAL_AKM:
      return payload;
    case RESET_LIST_SOAL_AKM:
      return initialState;
    default:
      return state;
  }
};

export default createSoalReducer;
