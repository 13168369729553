import { combineReducers } from "redux";
import sidebarShowReducer from "./sidebarShowReducer";
import createSoalReducer from "./createSoalReducer";
import listSoalReducer from "./listSoalReducer";
import listUjianReducer from "./listUjianReducer";
import listArticleReducer from "./listArticleReducer";
import listUserReducer from "./listUserReducer";
import dataExamReducer from "./dataExamReducer";
import dataSoalReducer from "./dataSoalReducer";
import userSoalReducer from "./userSoalReducer";
import userReducer from "./userReducer";
const rootReducer = combineReducers({
  sidebarShow: sidebarShowReducer,
  listArticle: listArticleReducer,
  createSoal: createSoalReducer,
  listUjian: listUjianReducer,
  listSoal: listSoalReducer,
  listUser: listUserReducer,
  dataExam: dataExamReducer,
  dataSoal: dataSoalReducer,
  userSoal: userSoalReducer,
  user: userReducer,
});

export default rootReducer;
