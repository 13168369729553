import { SET_LIST_ARTICLE, RESET_LIST_ARTICLE } from "../actions";
const initialState = [];

const createSoalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_ARTICLE:
      return payload;
    case RESET_LIST_ARTICLE:
      return initialState;
    default:
      return state;
  }
};

export default createSoalReducer;
